/*###########################################################################
// Insights hover
//#########################################################################*/

import { gsap } from "gsap"

const insightsHover = {
  config: {
    insight: '.insight, .insightSlim'
  },

  elements: {
    insights: null
  },

  init() {
    const self = this

    self.elements.insights = document.querySelectorAll(self.config.insight)

    if (self.elements.insights !== null && window.innerWidth > 1024) {
      for (let i = 0; i < self.elements.insights.length; i++) {
        const insight = self.elements.insights[i]

        self.handleInsightHover(insight, false)
      }

			self.handleDocumentMouseLeave()

      if (typeof htmx !== 'undefined') {
        htmx.on('htmx:afterSwap', function (event) {
          setTimeout(function () {
            self.elements.insights = document.querySelectorAll(self.config.insight)

            if (self.elements.insights !== null) {
              for (let i = 0; i < self.elements.insights.length; i++) {
                const insight = self.elements.insights[i]

                self.handleInsightHover(insight, true)
              }
            }

						self.handleDocumentMouseLeave()
          }, 250)
        })
      }
    }
  },

	handleDocumentMouseLeave() {
		const self = this

		// Detect mouse leave on document to prevent images from staying visible in odd positions
		//
		document.addEventListener('mouseleave', function(e) {
			if (self.elements.insights !== null) {
				for (let i = 0; i < self.elements.insights.length; i++) {
					const insight = self.elements.insights[i]
					const image = insight.querySelector('img')
					gsap.set(image, { autoAlpha: 0 })
				}
			}
		})
	},

  handleInsightHover(insight, htmxSwap) {
    const self = this

    const image = insight.querySelector('img')
    let imageWidth = image.clientWidth
    let imageHeight = image.clientHeight

    image.addEventListener('load', function() {
      imageWidth = image.clientWidth
      imageHeight = image.clientHeight
    })

    // Initial check for hover (for when sprig updates)
    //
    if (htmxSwap === true) {
      gsap.to(image, { autoAlpha: 0 })

      const event = new Event('mousemove');
      window.dispatchEvent(event);
    }

    insight.addEventListener('mouseenter', function(e) {
			if (document.hasFocus()) {
				gsap.set(image, { x: (e.clientX - imageWidth / 2), y: (e.clientY - imageHeight / 2) })

				gsap.to(image, { autoAlpha: 1, duration: 0.005 })
			}
    })

    insight.addEventListener('mousemove', function(e) {
			if (document.hasFocus()) {
				gsap.to(image, { x: (e.clientX - imageWidth / 2), y: (e.clientY - imageHeight / 2), duration: 0.005 })

				if (htmxSwap === true) {
					if (window.getComputedStyle(image).getPropertyValue('opacity') == '0') {
						gsap.set(image, { x: (e.clientX - imageWidth / 2), y: (e.clientY - imageHeight / 2) })
						gsap.set(image, { autoAlpha: 1 })
					}
				}
			}
    })

    insight.addEventListener('mouseleave', function(e) {
      gsap.set(image, { autoAlpha: 0 })
    })
  }
}

window.addEventListener('load', function () {
  insightsHover.init()
})
